<template>
  <div style="margin-bottom:56px;" class="hidden-sm-and-up">
    <div class="mobile_nav_wrap hidden-sm-and-up">
      <div class="navbar_left">
        <img src="@/assets/logo_mini.png" alt="DTSPD" />
      </div>
      <div class="navbar_right">
        <!-- <div class="nav_tiyan" @click="gotoExperience">立即体验</div> -->
        <div class="nav_btns" @click="moveMenu">
          <i class="el-icon-menu"></i>
        </div>
      </div>
    </div>
    <Affix>
      <div class="nav_menu" ref="menu">
        <div class = "menu_item" v-for="(item,index) in navList" :key="index" :ref="item.ref" :class="activeIndex === item.index ? 'active' : ''" @click="changeCurrentPage(item.index)">
          <div class = "menu-item-link">
            <span class="title">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </Affix>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuShow: false,
      productShow: false,
      centerShow: false,
      fireShow: false,
      activeIndex: '1',
      first: '',
      secound: '',
      navBackground:'#fff',
      navList: [
        {
          index: '1',
          title: '首页',
          subtitle: 'HOME',
          ref: 'home'
        },
        {
          index: '2',
          title: '关于我们',
          subtitle: 'INTRODUCTION',
          ref: 'introduction'
        },
        {
          index: '3',
          title: '企业动态',
          subtitle: 'NEWS',
          ref: 'news'
        },
        {
          index: '4',
          title: '业务团队',
          subtitle: 'TEAM',
          ref: 'team'
        },
        {
          index: '5',
          title: '业绩与荣誉',
          subtitle: 'ACHIVEMENT',
          ref: 'achivement'
        },
        {
          index: '6',
          title: '党建专栏',
          subtitle: 'PARTY AFFAIRS',
          ref: 'partyaffairs'
        },
        {
          index: '7',
          title: '人才招聘',
          subtitle: 'RECRUIT',
          ref: 'recruit'
        },
        {
          index: '8',
          title: '联系我们',
          subtitle: 'CONTACT',
          ref: 'contact'
        }
      ]
    }
  },
  props: {
    selectIndex: {
      type: String,
      default: '1',
    },
  },
  mounted() {
    this.activeIndex = this.selectIndex
  },
  methods: {
    moveMenu() {
      this.menuShow = !this.menuShow
      if (this.menuShow) {
        this.$refs.menu.style.top = '56PX'
      } else {
        this.$refs.menu.style.top = '-1000PX'
      }
    },
    changeProductShow() {
      this.productShow = !this.productShow
      this.centerShow = false
      this.fireShow = false
    },
    changeCenterShow() {
      this.centerShow = !this.centerShow
      this.fireShow = false
      this.productShow = false
    },
    changefireShow() {
      this.fireShow = !this.fireShow
      this.centerShow = false
      this.productShow = false
    },
    changeCurrentPage(index) {
      this.activeIndex = index
      switch (this.activeIndex) {
        case '1':
          this.$router.push('/home').catch(() => {})
          break
        case '2':
          this.$router.push('/introduction/1').catch(() => {})
          break
        case '3':
          this.$router.push('/news/1').catch(() => {})
          break
        case '4':
          this.$router.push('/team/1').catch(() => {})
          break
        case '5':
          this.$router.push('/achivement/1').catch(() => {})
          break
        case '6':
          this.$router.push('/partyaffairs').catch(() => {})
          break
        case '7':
          this.$router.push('/recruit').catch(() => {})
          break
        case '8':
          this.$router.push('/contact').catch(() => {})
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile_nav_wrap {
  box-sizing: border-box;
  width: 100%;
  height: 56PX;
  background: #fff;
  z-index: 1000;
  display: flex;
  padding: 15PX 0 15PX 15PX;
  align-items: center;
  position: fixed;
  top: 0;
  .navbar_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 200px;
    }
  }
  .navbar_right {
    flex: 2;
    height: 56PX;
    display: flex;
    justify-content: flex-end;
    background: #fff; 
    .nav_tiyan {
      width: 88PX;
      height: 100%;
      background: #1a63c4;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14PX;
    }

    .nav_btns {
      width: 56PX;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      i{
        font-size: 30PX;
      }
    }
  }
}
.active{
  color: #0b1660 !important;
  font-weight: bold;
}
.nav_menu{
    position: absolute;
    width: 100%;
    height: 450PX;
    list-style: none;
    left: 0;
    right: 0;
    top: -1000PX;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #EFF0F1;
    box-shadow: 0 10PX 20PX rgba(0,0,0,.1);
    z-index: 10;
    transition: all 0.8s;
    .menu_item{
      width: 100%;
      padding: 20px 80px;
      font-size: 16PX;
      background: #EFF0F1;
      color: #1c212d;
      display: flex;
      align-items: center;
    }
    .item_options{
      width: 100%;
      padding: 5PX 10PX;
      height: 150PX;
      font-size: 14PX;
      background: #fff;
      a{
        display: inline-block;
        width: 100%;
        color: #666;
        height: 25%;
        padding: 20px 30PX;
      }
    }
  }
</style>