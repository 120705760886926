<template>
  <div>
    <PCNavbar ref="navbar" selectIndex="7"/>
    <MobileNavBar selectIndex="7" />
    <div style="padding-top: 100px;">
      <div class = "box">
        <div class="about-title">人才招聘</div>
        <div class = "block-line"></div>
        <div class="about-container">
          <p style="line-height:3;text-align:center;">
            <b><span style="font-size:16px;">喜讯：大连市国土空间规划设计有限公司博士后创新实践基地正式挂牌！</span></b>
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">博士后创新实践基地作为加强国土空间规划人才引进和培养、推进产学研相结合的重要平台与抓手，将有效促进校企合作，有力推动自然资源和国土空间规划高层次人才培养、理论创新发展和科技成果转化，势必进一步增强企业科技创新能力和核心竞争力。</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">欢迎各方有识之士加入大连市国土空间规划设计有限公司博士后创新实践基地。拟进站的博士后候选人员可与公司进行沟通，根据专业特长和公司发展需要，选择相关研究课题。</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <b><span style="font-size:16px;line-height:3;">博士后招聘条件：</span></b>
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">1、已经获得博士学位或预计六个月内进行博士论文答辩的博士研究生；</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">2、年龄在 35 周岁以下，身体健康，品学兼优；</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">3、专业方向为城乡规划学、城乡规划与设计、土地管理、建筑学、 交通运输规划与管理、区域规划、地理信息技术等；</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">4、具有较强的研究能力、组织协调能力及敬业精神，具备带领科研团队技术攻关的能力，能够尽职尽责地完成博士后研究工作。</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <br />
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">有意向成为创新基地博士后研究人员者，请向公司提交以下材料： 个人学习工作简历；入站拟选课题计划梗概；博士毕业证及学位证书复印件（为毕业的提交论文进度证明）；博士学位论文及代表性学术论文及著作；个人近期免冠 2 寸照片 1 张。</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <br />
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">联系地址：大连市西岗区长春路 186 号，大连市国土空间规划设计有限公司博士后管理办公室</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">邮政编码：116011</span> 
          </p>
          <p style="text-indent:3em;text-align:justify;">
            <span style="font-size:16px;line-height:3;">联系人：人力资源 滕少洁</span> 
          </p>
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import PCNavbar from '@/components/navbar/index'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  components: {
    PCNavbar,
    MobileNavBar,
    CallMe,
    MobileCallme
  },
}
</script>

<style>
</style>