<template>
  <div class="navbar_wrap hidden-xs-only">
    <div class="navbar_container">
      <div class="navbar_left">
        <img src="@/assets/logo_index.png" alt="DTSPD" />
      </div>
      <div class="navbar_right">
        <div class = "menu-wrapper">
          <div class = "menu-item" v-for="(item,index) in navList" :key="index" :ref="item.ref" :class="activeIndex === item.index ? 'active' : ''" @click="changeCurrentPage(item.index)">
            <div class = "menu-item-link">
              <span class="title">{{ item.title }}</span>
              <span class="subtitle">{{ item.subtitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      first: '',
      secound: '',
      navBackground:'#fff',
      navList: [
        {
          index: '1',
          title: '首页',
          subtitle: 'HOME',
          ref: 'home'
        },
        {
          index: '2',
          title: '关于我们',
          subtitle: 'INTRODUCTION',
          ref: 'introduction'
        },
        {
          index: '3',
          title: '企业动态',
          subtitle: 'NEWS',
          ref: 'news'
        },
        {
          index: '4',
          title: '业务团队',
          subtitle: 'TEAM',
          ref: 'team'
        },
        {
          index: '5',
          title: '业绩与荣誉',
          subtitle: 'ACHIVEMENT',
          ref: 'achivement'
        },
        {
          index: '6',
          title: '党建专栏',
          subtitle: 'PARTY AFFAIRS',
          ref: 'partyaffairs'
        },
        {
          index: '7',
          title: '人才招聘',
          subtitle: 'RECRUIT',
          ref: 'recruit'
        },
        {
          index: '8',
          title: '联系我们',
          subtitle: 'CONTACT',
          ref: 'contact'
        }
      ]
    }
  },
  props: {
    selectIndex: {
      type: String,
      default: '1',
    },
  },
  mounted() {
    this.activeIndex = this.selectIndex
  },
  methods: {
    changeCurrentPage(index) {
      this.activeIndex = index
      switch (this.activeIndex) {
        case '1':
          this.$router.push('/home').catch(() => {})
          break
        case '2':
          this.$router.push('/introduction/1').catch(() => {})
          break
        case '3':
          this.$router.push('/news/1').catch(() => {})
          break
        case '4':
          this.$router.push('/team/1').catch(() => {})
          break
        case '5':
          this.$router.push('/achivement/1').catch(() => {})
          break
        case '6':
          this.$router.push('/partyaffairs').catch(() => {})
          break
        case '7':
          this.$router.push('/recruit').catch(() => {})
          break
        case '8':
          this.$router.push('/contact').catch(() => {})
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar_wrap {
  width: 100%;
  height: 130px;
  background: #f0f0f0ee;
  backdrop-filter: saturate(180%) blur(0.5rem);
  display: flex;
  align-items: center;
  position: fixed;
  border-bottom: 1px dashed rgb(11, 22, 96);
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  .navbar_container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar_left {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 420px;
        cursor: pointer;
      }
    }
    .navbar_right {
      height: 100%;
      margin-left: 10px;
      .menu-wrapper {
        height: 100%;
        display: flex;
        flex-direction: row;
        list-style: none;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
        position: relative;

        .menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          flex-shrink: 0;
          padding-left: 20px;
          padding-right: 20px;
        }

        .menu-item:hover {
          background-color: #0b1660aa;
          color: #fff;
          .subtitle {
            color: #fff;
          }
        }

        .menu-item:last-child:before {
          content: "";
          display: block;
          height: 12px;
          //border-right: 1px solid #fff;
          position: absolute;
          bottom: 24px;
          right: 48px;
        }

        .menu-item.active {
          background-color: #0b1660;
          color: #fff;
          .subtitle {
            color: #fff;
          }
        }

        .menu-item-link {
          //color: #646a73;
          font-weight: bold;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
          position: relative;
          a {
            display: inline-block;
            color: #333;
            transition: color .3s;
          }
          .title {
            display: block;
          }
          .subtitle {
            font-size: 12px;
            display: block;
            margin-top: 5px;
            color: #909399;
          }

          .router-link-exact-active {
            color: #3370ff;
            font-weight: 600;

            span:after {
              content: "";
              display: block;
              height: 2px;
              position: absolute;
              width: 26px;
              left: calc(50% - 13px);
              bottom: 14px;
              background-color: #3370ff;
            }
          }
        }
      }
    }
  }
}
</style>